import * as React from 'react';

import { Check } from '@cfa-icons/system';
import RoleType from 'app/constants/ApiRole';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createRole } from 'app/store/roles/actions';
import { usePodRoles } from 'app/store/roles/selectors';
import { displayErrorToast } from 'app/utils/toasts/displayToast';

import {
  Button,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
} from 'cfa-react-components';

export const CreateRole = () => {
  const dispatch = useAppDispatch();
  const [role, setRole] = React.useState('');
  const [roleError, setRoleError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [rank, setRank] = React.useState<string | undefined>(undefined);
  const [showErrors, setShowErrors] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');

  const podRoles = usePodRoles();
  // When the user clicks on the "Create New" button
  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };
  // The input field for the new role name is cleared when the user closes handle without creating a new role
  const handleClose = () => {
    setRoleError(false);
    setOpen(false);
    setRole('');
    setRank(undefined);
    setShowErrors(false);
    setErrorText('');
  };

  const isInvalidRank = (str1: string): boolean => {
    const num1 = parseInt(str1, 10);
    if (isNaN(num1)) {
      return true;
    }

    return num1 < 0 || num1 > 100;
  };
  const handleSaveClose = async () => {
    setShowErrors(true);
    if (rank === '' || rank === undefined) {
      setErrorText('Field required');
      return;
    }
    if (isInvalidRank(rank)) {
      setErrorText('Rank must be between 0 and 100.');
      return;
    }
    setRole(role);
    try {
      if (podRoles) {
        const roleExists = podRoles.some(r => r.name === role);
        if (roleExists) {
          setRoleError(true);
          return;
        }
      }
      await dispatch(createRole(role, RoleType.POD_ROLE, rank))
        .then(() => {
          setOpen(false);
          setRole(role);
          setErrorText('');
          setRank(undefined);
          setShowErrors(false);
        })
        .catch(error => {
          displayErrorToast(error.message, ACTIONS.createRole);
        });
    } catch (error) {}
  };

  return (
    <>
      <Button
        data-cy="create-pod-role-btn-full"
        className="create_btn"
        size="md"
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{
          marginLeft: '20px',
          marginBottom: '10px',
          fontSize: '17px',
        }}
      >
        <React.Fragment key=".0">
          <Icon icon={Check} data-cy="create-pod-role-btn" />
          Create New
        </React.Fragment>
      </Button>

      <Modal
        data-cy="cancel-role"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Role</ModalHeader>
          <ModalBody>
            To create a new role, enter a new role name.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <React.Fragment key=".0">
                <TextField
                  label="Role"
                  required
                  defaultValue=""
                  errorText={roleError ? 'Pod role name already exists.' : ''}
                  onChange={e => {
                    setRole(e.target.value);
                    setRoleError(false);
                  }}
                  placeholder="Enter new role name"
                  fullWidth
                  data-cy="pod-role-in"
                />
              </React.Fragment>
              <React.Fragment key=".1">
                <TextField
                  errorText={showErrors ? errorText : ''}
                  label="Rank"
                  required
                  onChange={e => setRank(e.target.value)}
                  value={rank !== undefined ? rank.toString() : ''}
                  placeholder="Enter a rank value [0, 100]"
                  fullWidth
                  data-cy="pod-rank-in-rank"
                />
              </React.Fragment>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role || roleError}
              data-cy="post-pod-role-btn"
            >
              Create Role
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreateRole;
