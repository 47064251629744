import { IdentityResponse } from 'app/types/IdentityUser';
import { Pod } from 'app/types/Pod';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const podApiClient = createApiClient(AppConstants.URL.NEW_PODS);
const apiClient = createApiClient(AppConstants.URL.PODS);
const podsApi = {
  pods: {
    getAllPods: () => podApiClient.get(``).json<Pod[]>(),
    createPod: (name: string, deptId: string, info?: string) => {
      const params: { name: string; info?: string; deptId: string } = {
        name: name,
        deptId: deptId,
      };
      if (info) {
        params.info = info;
      }
      return podApiClient
        .post(``, {
          json: params,
        })
        .json<Pod>();
    },
    deletePod: (pod_id: string) => podApiClient.delete(`${pod_id}`).json(),
    updatePod: (
      pod_id: string,
      name?: string,
      info?: string,
      deptId?: string,
    ) => {
      const params: { name?: string; info?: string; deptId?: string } = {};
      if (name) {
        params.name = name;
      }
      if (info || info !== undefined) {
        params.info = info;
      }
      if (deptId) {
        params.deptId = deptId;
      }
      return podApiClient.put(`${pod_id}`, { json: params }).json<Pod>();
    },
    getPod: (id: string) => podApiClient.get(`${id}`).json<Pod>(),
  },
  members: {
    getStaff: (lastId?: string) =>
      apiClient
        .get('v2/identity/users', {
          searchParams: { audience: 'staff', lastId: lastId ?? '' },
        })
        .json<IdentityResponse>(),
    getContractors: (lastId?: string) =>
      apiClient
        .get('v2/identity/users', {
          searchParams: { audience: 'contractor', lastId: lastId ?? '' },
        })
        .json<IdentityResponse>(),
  },
};
export default podsApi;
