import Guild from 'app/types/Guild';
import { Card, CardContent, Typography } from 'cfa-react-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import './GuildsCard.scss';

export type GuildCardProps = {
  guild: Guild;
};

export const GuildCard = ({ guild }: GuildCardProps) => {
  const navigate = useNavigate();

  const handleClick = (guildID: string) => {
    navigate(`/guilds/${guildID}`);
  };

  return (
    <>
      <Card
        className="guild-card"
        elevation={8}
        variant="default"
        style={{
          width: '450px',
          height: '200px',
        }}
        onClick={() => handleClick(guild.id)}
      >
        <React.Fragment key=".0">
          <CardContent
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
              width: '450px',
              height: '192px',
              textAlign: 'center',
              overflow: 'scroll',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                className="guild-name"
                color="secondary"
                variant="h4"
                data-cy="guild-name"
                style={{
                  paddingTop: '3px',
                  paddingBottom: '0px',
                  overflow: 'clip',
                  wordBreak: 'break-word',
                  textAlign: 'center',
                }}
              >
                {guild.name}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  paddingTop: '3px',
                }}
              >
                {guild.info}
              </Typography>
            </div>
          </CardContent>
        </React.Fragment>
      </Card>
    </>
  );
};

export default GuildCard;
