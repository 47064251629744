import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Tag } from 'app/types/Tag';

interface TagState {
  entities: {
    [key: string]: Tag;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export const tagAdapter = createEntityAdapter<Tag>({
  selectId: a => a.tagName,
  sortComparer: (a, b) => a.tagName.localeCompare(b.tagName),
});
const initialState = tagAdapter.getInitialState({
  status: 'idle',
});

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setAllTags: tagAdapter.setAll,
    addTag: tagAdapter.addOne,
    deleteTag: tagAdapter.removeOne,
  },
});

export const { setAllTags, deleteTag, addTag } = tagSlice.actions;

export default tagSlice;
