import Department from 'app/types/Department';
import { Dropdown } from 'cfa-react-components';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

type EditDeptProps = {
  deptList: Department[];
  dept: Department;
  setSubDept: Dispatch<SetStateAction<Department>>;
};

export const EditGroupDepartment = ({
  dept,
  deptList,
  setSubDept,
}: EditDeptProps) => {
  return (
    <StyledDiv>
      {
        <div style={{ display: 'flex' }}>
          <Dropdown
            label="Subdepartment"
            onChange={e => {
              setSubDept(e!);
            }}
            options={deptList}
            getOptionId={option => option.id}
            getOptionText={option => option.name}
            renderOption={option => option.name}
            placeholder="Select a Sub-department"
            value={dept}
            data-cy="lt-subdepartment-field"
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              top: '2px',
              bottom: '30px',
              width: '300px',
            }}
          />
        </div>
      }
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  position: relative;
  top: 0px;
`;
