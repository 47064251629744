import { IconPlus } from '@tabler/icons-react';
import { Icon } from 'cfa-react-components';
import { PropsWithChildren, useContext } from 'react';
import { MembersContext } from './MembersContext';

export const SaveCloseButtonWrapper = ({ children }: PropsWithChildren<{}>) => {
  const { inputRowList, setInputRowList } = useContext(MembersContext);
  const handleAddInputRow = () => {
    setInputRowList([
      ...inputRowList,
      { index: inputRowList.length, role: null, user: null, deleted: false },
    ]);
    const container = document.getElementById('pod-members');
    if (container) {
      setTimeout(() => {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
      }, 0);
    }
  };
  return (
    <div className="saveAndCloseButtons">
      <div
        onClick={handleAddInputRow}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: '80px',
        }}
        data-cy="add-member-btn"
      >
        <Icon
          icon={IconPlus}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
          }}
          height="1.5em"
          width="1.5em"
        />
        <h4
          data-cy="add-member"
          style={{
            color: '#004F71',
            marginLeft: '.75rem',
            marginTop: '.15rem',
            cursor: 'pointer',
          }}
        >
          {' '}
          Add Member
        </h4>
      </div>
      {children}
      {/*  */}
    </div>
  );
};
