import { Check } from '@cfa-icons/system';
import RoleType from 'app/constants/ApiRole';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createRole } from 'app/store/roles/actions';
import { usePointOfContactRoles } from 'app/store/roles/selectors';
import { displayErrorToast } from 'app/utils/toasts/displayToast';

import {
  Button,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
} from 'cfa-react-components';
import * as React from 'react';

export const CreatePointOfContactRole = () => {
  const dispatch = useAppDispatch();
  const [role, setRole] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [roleError, setRoleError] = React.useState(false);
  const pointOfContactRoles = usePointOfContactRoles();

  // When the user clicks on the "Create New" button
  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };
  // The input field for the new role name is cleared when the user closes handle without creating a new role
  const handleClose = () => {
    setRoleError(false);
    setOpen(false);
    setRole('');
  };

  const handleSaveClose = async () => {
    try {
      if (pointOfContactRoles) {
        const roleExists = pointOfContactRoles.some(r => r.name === role);
        if (roleExists) {
          setRoleError(true);
          return;
        }
      }
      dispatch(createRole(role, RoleType.CONSULTANT_ROLE))
        .then(() => {
          setOpen(false);
          setRole(role);
        })
        .catch(error => {
          displayErrorToast(error.message, ACTIONS.createRole);
        });
    } catch {}
  };
  return (
    <>
      <Button
        className="create_btn"
        size="md"
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{
          marginLeft: '20px',
          marginBottom: '10px',
          fontSize: '17px',
        }}
      >
        <React.Fragment key=".0">
          <Icon icon={Check} data-cy="create-point-of-contact-role-btn" />
          Create New
        </React.Fragment>
      </Button>

      <Modal
        data-cy="cancel-point-of-contact"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Point of Contact Role</ModalHeader>
          <ModalBody>
            To create a new point of contact role, enter a new point of contact
            role name.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <React.Fragment key=".0">
                <TextField
                  label="Point Of Contact Role"
                  required
                  errorText={
                    roleError
                      ? 'Point of contact role name already exists.'
                      : ''
                  }
                  onChange={e => {
                    setRole(e.target.value);
                    setRoleError(false);
                  }}
                  defaultValue=""
                  placeholder="Role Name"
                  fullWidth
                  data-cy="point-of-contact-role-in"
                />
              </React.Fragment>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role}
              data-cy="post-point-of-contact-role-btn"
            >
              Create Point Of Contact Role
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreatePointOfContactRole;
