import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { EditGroupDepartment } from 'app/components/shared/ProfilePages/EditGroupDepartment';
import { EditDescription } from 'app/components/shared/ProfilePages/EditGroupDescription';
import { EditMembers } from 'app/components/shared/ProfilePages/EditMembers';
import { EditTitle } from 'app/components/shared/ProfilePages/EditTitle';
import { MembersContext } from 'app/components/shared/ProfilePages/MembersContext';
import { SaveAndCloseButtons } from 'app/components/shared/ProfilePages/SaveAndCloseButtons';
import RoleType from 'app/constants/ApiRole';
import ApiState from 'app/constants/ApiState';
import { ACTIONS, UserMessaging } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import {
  createLeadershipMembers,
  reloadTeam,
  removeLeadershipMembers,
  removeLeadershipTeam,
  updateLeadershipMembers,
  updateLeadershipTeam,
} from 'app/store/leadership/actions';
import {
  selectLeadershipById,
  selectLeadershipMembersByLeadershipId,
} from 'app/store/leadership/selectors';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import { selectLoadUserStatus, selectUsers } from 'app/store/users/selectors';
import Department from 'app/types/Department';
import { InputRowType } from 'app/types/InputRowType';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { shouldEnableSaveGroups } from 'app/utils/shouldEnableSaveGroups';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { getMembersChangeSet } from 'app/utils/useGetMembersChangeSet';
import { Breadcrumbs, LoadingIndicator } from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Leadership.scss';

export const LeadershipEditProfilePage = () => {
  const navigate = useNavigate();

  const { leadershipId } = useParams() as { leadershipId: string };
  const leadership = useAppSelector(selectLeadershipById(leadershipId));
  const deptList = useAppSelector(selectDepartments);
  const currentDept = useAppSelector(selectDepartmentById(leadership?.deptId!));

  // const [titleEditMode, setTitleEditMode] = useState(false);
  // const [infoEditMode, setInfoEditMode] = useState(false);
  // const titleRef = useRef<TextFieldType>(null);
  // const infoRef = useRef<TextFieldType>(null);

  const [infoText, setInfoText] = useState(leadership?.info);
  const [ltName, setLtName] = useState(leadership?.name);
  const [dept, setDept] = React.useState<Department>(currentDept!);
  const [isDisabled, setIsDisabled] = useState(true);
  const rolesList = useLeadershipRoles();
  const users = useAppSelector(selectUsers);
  const userStatus = useAppSelector(selectLoadUserStatus);

  const leadershipMembers = useAppSelector(
    selectLeadershipMembersByLeadershipId(leadershipId),
  );
  const dispatch = useAppDispatch();
  const allowLeadershipEdit: boolean = allowEditLT();

  const [inputRowList, setInputRowList] = useState<InputRowType[]>(
    [] as InputRowType[],
  );

  // useEffect(() => {
  //   const fetchExistingLeadershipMembers = () => {
  //     // Fetch existing leadership members by leadership id
  //     // Populate inputRowList with existing leadership members if they exist
  //     if (leadershipMembers) {
  //       setInputRowList(
  //         leadershipMembers.map((member, index) => ({
  //           index: index,
  //           role: rolesList.find(role => role.id === member.roleId) ?? null,
  //           user: users.find(user => user.GUID === member.userId) ?? null,
  //           deleted: false,
  //         })),
  //       );
  //     }
  //   };
  //   fetchExistingLeadershipMembers();
  // }, [users]);

  // const handleAddInputRow = () => {
  //   setInputRowList([
  //     ...inputRowList,
  //     { index: inputRowList.length, role: null, user: null, deleted: false },
  //   ]);
  // };

  useEffect(() => {
    setIsDisabled(!shouldEnableSaveButton());
  }, [inputRowList, ltName, infoText, dept]);

  const shouldEnableSaveButton = () => {
    const groupCheckFunc = () => {
      if (ltName !== leadership?.name) {
        return true;
      }

      if (infoText !== leadership?.info) {
        return true;
      }

      if (dept != currentDept) {
        return true;
      }

      return false;
    };
    return shouldEnableSaveGroups(
      inputRowList,
      leadershipMembers!,
      groupCheckFunc,
    );
  };

  // const handleChangeInputRow = (
  //   index: number,
  //   tempRole: Role | null,
  //   tempUser: IdentityUser | null,
  //   from: string,
  // ) => {
  //   const tempInputRows = [...inputRowList];
  //   if (from == 'USER') {
  //     tempInputRows[index] = {
  //       index: tempInputRows[index].index,
  //       role: tempInputRows[index].role,
  //       user: tempUser,
  //       deleted: false,
  //     };
  //   }
  //   if (from == 'ROLE') {
  //     tempInputRows[index] = {
  //       index: tempInputRows[index].index,
  //       role: tempRole,
  //       user: tempInputRows[index].user,
  //       deleted: false,
  //     };
  //   }
  //   setInputRowList(tempInputRows);
  // };

  // const handleDeleteInputRow = (indexToDelete: number) => {
  //   const tempInputRows = [...inputRowList];
  //   tempInputRows[indexToDelete] = {
  //     index: tempInputRows[indexToDelete].index,
  //     role: tempInputRows[indexToDelete].role,
  //     user: tempInputRows[indexToDelete].user,
  //     deleted: true,
  //   };
  //   setInputRowList(tempInputRows);
  // };

  const handleCloseLT = () => {
    if (leadership) {
      navigate(`/leadership-teams/${leadership.id}`);
    }
  };

  const saveDeleteLeadership = () => {
    if (leadershipMembers) {
      let tempMemberIds: string[] = [];
      leadershipMembers.forEach(member => {
        tempMemberIds.push(member.userId);
      });
      dispatch(removeLeadershipMembers(leadership!, tempMemberIds));
    }

    if (leadership?.members?.length === 0) {
      dispatch(removeLeadershipTeam(leadershipId))
        .then(() => navigate(`/leadership-teams`))
        .catch(e => {
          displayErrorToast(e.message, ACTIONS.deleteLeadership, leadershipId);
        });
    } else {
      displayErrorToast(
        UserMessaging.backupErrorMessaging.membersStillActive,
        ACTIONS.deleteLeadership,
        leadershipId,
      );
    }
  };

  // const compareLists = (
  //   leadershipMembers: Member[],
  //   inputRowList: InputRowType[],
  // ) => {
  //   const originalLeadershipMembers = new Map<string, string>();
  //   const currentMembers = new Map<string, string>();
  //   leadershipMembers.forEach(member => {
  //     originalLeadershipMembers.set(member.userId, member.roleId ?? '');
  //   });
  //   inputRowList.forEach(member => {
  //     if (member.deleted) {
  //       return;
  //     }
  //     if (member.user?.GUID) {
  //       currentMembers.set(member.user?.GUID, member.role?.id ?? '');
  //     }
  //   });

  //   const deletedList = [] as string[];
  //   for (const [user_id, role_id] of originalLeadershipMembers.entries()) {
  //     if (!currentMembers.has(user_id)) {
  //       deletedList.push(user_id);
  //     }
  //   }
  //   const addedList = [];
  //   const updatedList = [];
  //   for (const [user_id, role_id] of currentMembers.entries()) {
  //     if (!originalLeadershipMembers.has(user_id)) {
  //       addedList.push({ user_id: user_id, role_id: role_id });
  //     } else if (originalLeadershipMembers.get(user_id) !== role_id) {
  //       updatedList.push({ user_id: user_id, role_id: role_id });
  //     }
  //   }

  //   return { added: addedList, deleted: deletedList, updated: updatedList };
  // };

  // useRevertEdit(titleRef, setTitleEditMode, 'infoPencil');
  // useRevertEdit(infoRef, setInfoEditMode, 'infoPencil');

  const updateNameDescription = async () => {
    if (
      leadership &&
      (ltName !== leadership.name ||
        infoText !== leadership.info ||
        leadership.deptId !== dept?.id!)
    ) {
      await dispatch(
        updateLeadershipTeam(leadershipId, ltName, infoText, dept?.id!),
      ).catch(e => {
        displayErrorToast(e.message, ACTIONS.editLeadership, leadershipId);
      });
    }
  };

  const saveUpdateLt = async () => {
    // setLoading(true);
    updateNameDescription();
    if (!leadership) {
      return;
    }
    await saveUpdateLeadership();
    navigate(`/leadership-teams/${leadershipId}`);
    // setLoading(false);
  };

  const saveUpdateLeadership = async () => {
    if (leadership) {
      // let tempLeadership: LeadershipTeam = {
      //   ...leadership,
      //   name: ltName!,
      //   info: infoText,
      // };
      // if (
      //   leadership.name !== ltName ||
      //   leadership.info !== infoText ||
      //   leadership.deptId !== dept
      // ) {
      //   await dispatch(
      //     updateLeadershipTeam(leadershipId, ltName, infoText, dept),
      //   ).catch(e => {
      //     displayErrorToast(e.message, ACTIONS.editLeadership, leadershipId);
      //   });
      // }

      // const { added, deleted, updated } = compareLists(
      //   leadershipMembers ?? [],
      //   inputRowList,
      // );
      // if (added.length === 0 && deleted.length === 0 && updated.length === 0) {
      //   return;
      // }

      // let newMembers: IdentityUser[] = [];
      // const addedRoles: Role[] = [];
      // added.forEach(async member => {
      //   const user = users.find(user => user.GUID === member.user_id);
      //   let role = rolesList.find(role => role.id === member.role_id);
      //   if (user && role && tempLeadership) {
      //     newMembers.push(user);
      //     addedRoles.push(role);
      //   }
      // });

      // let changedMembers: Member[] = [];
      // const updatedRoles: Role[] = [];
      // updated.forEach(async member => {
      //   const role = rolesList.find(role => role.id === member.role_id);
      //   const teamMember = leadershipMembers?.find(
      //     lMember => lMember.userId === member.user_id,
      //   );
      //   if (role && teamMember) {
      //     changedMembers.push(teamMember);
      //     updatedRoles.push(role);
      //   }
      // });

      // let memberIds: string[] = [];
      // deleted.forEach(async memberId => {
      //   memberIds.push(memberId);
      // });

      const result = getMembersChangeSet(
        leadership,
        ltName!,
        infoText!,
        rolesList,
        leadershipMembers!,
        inputRowList,
        RoleType.LT_ROLE,
        users,
      );
      if (!result) {
        return;
      }
      const {
        tempGroup,
        deletedMemberIds,
        newMembers,
        addedRoles,
        changedMembers,
        updatedRoles,
      } = result;
      await Promise.all([
        dispatch(
          createLeadershipMembers(tempGroup, newMembers, addedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.createMember, leadershipId);
        }),
        dispatch(
          updateLeadershipMembers(tempGroup, changedMembers, updatedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.editMember, leadershipId);
        }),
        dispatch(removeLeadershipMembers(tempGroup, deletedMemberIds)).catch(
          e => {
            displayErrorToast(e.message, ACTIONS.deleteMember, leadershipId);
          },
        ),
      ]).catch(e => {
        console.log(e);
      });
      dispatch(reloadTeam(leadershipId));
    }
  };

  if (userStatus.state == ApiState.IDLE) {
    return leadership ? (
      <>
        <Navigation />
        <div className="main-container">
          <div className="top-section">
            <Breadcrumbs
              breadcrumbs={[
                {
                  label: 'Leadership Teams',
                  onClick: function backToLeadership() {
                    navigate('/leadership-teams');
                  },
                },
                {
                  label: leadership?.name,
                },
              ]}
            />
          </div>
          <EditTitle titleText={ltName} setTitleText={setLtName} />

          <EditGroupDepartment
            dept={dept!}
            deptList={deptList}
            setSubDept={setDept}
          />

          <EditDescription infoText={infoText} setInfoText={setInfoText} />

          <MembersContext.Provider value={{ inputRowList, setInputRowList }}>
            <EditMembers members={leadershipMembers} rolesList={rolesList}>
              <SaveAndCloseButtons
                isDisabled={isDisabled}
                handleCloseGroup={handleCloseLT}
                saveUpdateGroup={saveUpdateLt}
                allowEditGroup={allowLeadershipEdit}
              />
            </EditMembers>
          </MembersContext.Provider>

          <DeleteObjectModal
            type="Leadership Team"
            itemName={leadership.name}
            handleDelete={saveDeleteLeadership}
            handleClose={function noRefCheck() {}}
            allowDelete={allowLeadershipEdit}
          />
        </div>
        <Footer></Footer>
      </>
    ) : (
      <PageNotFound></PageNotFound>
    );
  } else {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  }
};
