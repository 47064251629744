import tagsApi from 'app/services/TagsApi';
import { AppAction } from '..';
import { addTag, deleteTag, setAllTags } from './slice';

export const loadTags = (): AppAction<Promise<void>> => async dispatch => {
  try {
    const resp = await tagsApi.tags.getTags();
    dispatch(setAllTags(resp));
  } catch (error) {
    console.error('Error loading tags:', error);
  }
};

export const createTag =
  (tagName: string): AppAction<Promise<void>> =>
  dispatch => {
    return tagsApi.tags.createTag(tagName).then(resp => {
      dispatch(addTag(resp));
    });
  };

export const removeTag =
  (tagName: string): AppAction<Promise<void>> =>
  dispatch => {
    return tagsApi.tags.deleteTag(tagName).then(resp => {
      dispatch(deleteTag(tagName));
    });
  };
