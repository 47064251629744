import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

import RoleType from 'app/constants/ApiRole';
import { Role } from 'app/types/Role';

const apiClient = createApiClient(AppConstants.URL.ROLES);
const rolesAPI = {
  roles: {
    createRole: (
      name: string,
      type: RoleType = RoleType.POD_ROLE,
      rank?: string,
    ) => {
      const numericRank = rank ? Number(rank) : undefined; // Convert rank to a number if it's provided
      return apiClient
        .post(``, { json: { name, type, rank: numericRank } })
        .json<Role>();
    },

    getRoles: () => apiClient.get('').json<Role[]>(),

    deleteRole: (id: string) => apiClient.delete(`${id}`).json(),

    updateRole: (id: string, name?: string, rank?: string, type?: RoleType) => {
      const numericRank = rank ? Number(rank) : undefined; // Convert rank to a number if it's provided
      return apiClient
        .put(`${id}`, {
          json: { name, rank: numericRank, type },
        })
        .json<Role>();
    },
  },
};

export default rolesAPI;
