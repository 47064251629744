import { AppAction } from '..';
import { loadDepartments } from '../dept/actions';
import { loadGuilds } from '../guilds/actions';
import { loadTeams } from '../leadership/actions';
import { loadPods } from '../pods/actions';
import { loadPointsOfContact } from '../points_of_contact/actions';
import { loadRoles } from '../roles/actions';
import { loadTags } from '../tags/actions';
import { loadUsers } from '../users/actions';
import { selectIsInitialized } from './selectors';
import { setIsInitialized } from './slice';

export const initializeApp =
  (): AppAction<Promise<void>> => async (dispatch, getState) => {
    const state = getState();
    const isInitialized = selectIsInitialized(state);
    if (!isInitialized) {
      dispatch(loadUsers());
      dispatch(loadDepartments());
      dispatch(loadRoles());
      dispatch(loadPods());
      dispatch(loadTeams());
      dispatch(loadPointsOfContact());
      dispatch(loadTags());
      dispatch(loadGuilds());
      dispatch(setIsInitialized());
    }
  };
