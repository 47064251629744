import { Member } from 'app/types/Member';
import Role from 'app/types/Role';
import { Typography } from 'cfa-react-components';
import React, { useEffect, useState } from 'react';
import { MemberRoles } from './MemberRoles';

type GroupProps = {
  members?: Member[];
};

type RoleSelectionProps<T> = {
  members: Member[];
  rolesList: Role[];
  group: T;
  staffOnly?: boolean;
};
// The values as arguments come from the PodCard.js file.
export const RoleGroups = <T extends GroupProps>({
  rolesList,
  staffOnly,
  group,
  members,
}: RoleSelectionProps<T>) => {
  const [memberRolesMap, setRolesMap] = useState(new Map<string, Member[]>());
  const [sortedMemberRolesMap, setSortedMemberRolesMap] = useState(
    new Map<string, Member[]>(),
  );

  function addMemberToMap(
    map: Map<string, Member[]>,
    member: Member,
    roleName: string,
  ) {
    if (map.has(roleName)) {
      let tempArray = [...map.get(roleName)!];
      tempArray.push(member);
      map.set(roleName, tempArray);
    } else {
      map.set(roleName, [member]);
    }
  }

  function createRolesMap(members: Member[]) {
    let tempMap: Map<string, Member[]> = new Map();
    members.forEach(member => {
      if ((staffOnly && !member.isContingent) || !staffOnly) {
        if (!member.roleId) {
          addMemberToMap(tempMap, member, 'Members');
        } else {
          let role =
            rolesList.find(role => role.id === member.roleId) ?? undefined;
          if (role) {
            addMemberToMap(tempMap, member, role.name);
          } else {
            addMemberToMap(tempMap, member, 'Members');
          }
        }
      }
    });
    const sortedMap = new Map(
      Array.from(tempMap).sort((firstRole, secondRole) => {
        if (secondRole[0] === 'Members' || secondRole[0] === 'Member') {
          return -1; // Place "members" at the end
        } else if (firstRole[0] === 'Members' || firstRole[0] === 'Member') {
          return 1; // Place "members" at the end
        } else {
          return firstRole[0].localeCompare(secondRole[0]); // Sort alphabetically
        }
      }),
    );
    setRolesMap(sortedMap);
  }

  function sortRoles() {
    const rolesByType: Map<string, (Role & { members: Member[] })[]> =
      new Map();
    const newSortedMemberRolesMap = new Map<string, Member[]>();
    [...memberRolesMap].map(([key, members]) => {
      const role = rolesList.find(role => role.name === key);
      if (role) {
        if (!rolesByType.has(role.type)) {
          rolesByType.set(role.type, []);
        }
        rolesByType.get(role.type)!.push({ ...role, members });
      }
    });
    rolesByType.forEach((roles, type) => {
      roles.sort((a: Role, b: Role) => {
        const rankA = Number(a.rank);
        const rankB = Number(b.rank);
        if (Number.isNaN(rankA) && !Number.isNaN(rankB)) {
          return 1;
        }
        if (!Number.isNaN(rankA) && Number.isNaN(rankB)) {
          return -1;
        }
        return rankA - rankB;
      });
    });

    rolesByType.forEach((roles: Role[], type) => {
      roles.forEach(role => {
        const roleMembers = members.filter(m => m.roleId === role.id);
        if (roleMembers.length > 0) {
          const sortedMembers = roleMembers.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          newSortedMemberRolesMap.set(role.name, sortedMembers);
        }
      });
    });
    setSortedMemberRolesMap(newSortedMemberRolesMap);
  }

  useEffect(() => {
    sortRoles();
  }, [memberRolesMap]);

  useEffect(() => {
    if (members.length > 0) {
      createRolesMap(members);
    }
  }, [members, staffOnly]);

  if (group.members?.length === 0 || undefined) {
    return (
      <>
        <Typography variant="body1" fontWeight="bold">
          Members
        </Typography>
        <Typography variant="body2"> No members in this pod. </Typography>
      </>
    );
  }
  return (
    <>
      {[...sortedMemberRolesMap].map(([key, members]) => {
        const roleName =
          (members ?? []).length > 1 && key !== 'Members' ? `${key}s` : key;

        return (
          <React.Fragment key={key}>
            {members ? (
              <>
                <MemberRoles key={key} roleName={roleName} members={members} />
              </>
            ) : (
              <>
                <Typography variant="h2" color="primary" align="center">
                  An unexpected error occurred.
                </Typography>
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
export default RoleGroups;
