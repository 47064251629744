import { ChevronDown } from '@cfa-icons/system';
import { useAppSelector } from 'app/store';
import { selectDepartmentById } from 'app/store/dept/selectors';
import { Department } from 'app/types/Department';
import { Pod } from 'app/types/Pod';
import Role from 'app/types/Role';
import { Card, CardContent, Icon, Row, Typography } from 'cfa-react-components';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import RoleGroups from '../CardRoleGroups/RoleGroups';
import './PodCard.scss';

export type PodCardProps = {
  rolesList: Role[];
  staffOnly?: boolean;
  currDept?: Department;
  pod: Pod;
  collapseAll?: boolean;
};

// The below values are passed as arguments from the pod.js file.
export const PodCard = ({
  rolesList,
  staffOnly,
  currDept,
  pod,
  collapseAll,
}: PodCardProps) => {
  rolesList?.sort((role1, role2) => role1.name.localeCompare(role2.name));

  const navigate = useNavigate();
  const [individualCollapse, setIndividualCollapse] = useState(true);

  const officialCollapse =
    collapseAll == undefined ? individualCollapse : collapseAll;

  const handleClick = (podId: string) => {
    navigate(`/pods/${podId}`);
  };

  const handleCollapse = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    setIndividualCollapse(!individualCollapse);
  };

  const tempDept = useAppSelector(selectDepartmentById(pod.deptId));
  const dept = currDept ? currDept : tempDept;

  return (
    // This section creates each pod card by reading in the above const values and mapping them appropriately.
    <>
      <Card
        className="pod-card"
        elevation={8}
        variant="default"
        onClick={e => handleCollapse(e)}
        data-cy="expand-pod"
      >
        <React.Fragment key=".0">
          <CardContent
            style={{
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            <div
              className="pod-header"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                height: 'max-content',
                minHeight: '80px',
              }}
            >
              <div className="name-arrow">
                <Typography
                  className="pod-name"
                  color="secondary"
                  variant="h4"
                  data-cy={`pod-name-${pod.id}`}
                  onClick={() => handleClick(pod.id)}
                  style={{
                    maxWidth: '200px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingRight: '1px',
                    overflow: 'unset',
                    wordBreak: 'break-word',
                    cursor: 'pointer',
                  }}
                >
                  {pod.name}
                </Typography>
                {collapseAll == undefined ? (
                  <Icon
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      paddingLeft: '4px',
                    }}
                    icon={ChevronDown}
                  ></Icon>
                ) : (
                  <></>
                )}
              </div>
              <Typography
                variant="body2"
                style={{
                  marginTop: '-10px',
                  marginBottom: '10px',
                  paddingTop: '6px',
                  alignSelf: 'flex-end',
                  marginRight: 'auto',
                }}
              >
                {dept?.name}
              </Typography>
            </div>
            <div
              className={officialCollapse ? 'pod-collapsed' : 'pod-uncollapsed'}
            >
              <RoleGroups<Pod>
                key={pod.name}
                staffOnly={staffOnly}
                rolesList={rolesList}
                group={pod}
                members={pod.members ?? []}
              />
              <Row
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '5px',
                }}
              >
                {/* <PodInfo podName={podName} /> */}
              </Row>
            </div>
          </CardContent>
        </React.Fragment>
      </Card>
    </>
  );
};

export default PodCard;
